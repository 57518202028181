
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$leishpalette-blue: (
    50 : #f2f9fe,
    100 : #deeffd,
    200 : #c8e5fc,
    300 : #b1dafb,
    400 : #a1d2fa,
    500 : #90caf9,
    600 : #88c5f8,
    700 : #7dbdf7,
    800 : #73b7f6,
    900 : #61abf5,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fafcff,
    A700 : #e0efff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$leishpalette-accent: (
    50 : #e3effa,
    100 : #bad6f2,
    200 : #8cbbe9,
    300 : #5e9fe0,
    400 : #3c8bd9,
    500 : #1976d2,
    600 : #166ecd,
    700 : #1263c7,
    800 : #0e59c1,
    900 : #0846b6,
    A100 : #e0eaff,
    A200 : #adc6ff,
    A400 : #7aa3ff,
    A700 : #6191ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$leishgemweb-primary: mat.define-palette(mat.$indigo-palette);
$leishgemweb-primary: mat.define-palette($leishpalette-blue);
$leishgemweb-accent: mat.define-palette($leishpalette-accent);
$leishgemweb-warn: mat.define-palette(mat.$red-palette);

$leishgemweb-typography: mat.define-typography-config();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$leishgemweb-theme: mat.define-light-theme((
  color: (
    primary: $leishgemweb-primary,
    accent: $leishgemweb-accent,
    warn: $leishgemweb-warn,
  ),
  typography: $leishgemweb-typography,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($leishgemweb-theme);
@include mat.core-theme($leishgemweb-theme);
@include mat.autocomplete-theme($leishgemweb-theme);
@include mat.button-theme($leishgemweb-theme);
@include mat.card-theme($leishgemweb-theme);
@include mat.chips-theme($leishgemweb-theme);
@include mat.fab-theme($leishgemweb-theme);
@include mat.icon-theme($leishgemweb-theme);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/* You can add global styles to this file, and also import other style files */

:root {
  // CSS variables for gene list and detail view colour code
  --color-yes: #00d2a5;
  --color-no: #f54db2;
  --color-maybe: rgb(0, 0, 0);
  --color-unknown: rgba(128, 128, 128, 0.1);
  --color-notsignificant: rgb(187, 187, 187);
  --color-positive: rgb(0, 0, 255);
  --color-negative: rgb(255, 0, 0);
  // CSS variables for global theming
  --mdc-elevated-card-container-shape: 8px; // TODO: Check if should instead be set via theming somehow
  // CSS variables for layout
  --layout-full-width: 1280px;
  --layout-searchbar-padding: 4px;
  --layout-searchbar-buttonsize: 48px;
  --layout-searchbar-height: 56px;
  --layout-card-padding: 8px;
  --layout-genelist-width: 390px;
  --layout-genelist-width: calc(14 * (var(--display-block-width) + var(--layout-genelist-narrowspacing)) + 5 * var(--layout-genelist-widespacing) + var(--scrollbar-width-allowance));
  // Gene list layout
  --display-block-width: min(24px, calc(100vw / 16.5));
  --display-block-width: min(24px, calc((100vw - var(--scrollbar-width-allowance) - 5 * var(--layout-genelist-widespacing)) / 14 - var(--layout-genelist-narrowspacing)));
  --display-block-height: 10px;
  --layout-genelist-narrowspacing: 0px;
  --layout-genelist-widespacing: 3px;
  --scrollbar-width-allowance: 24px; // best guess, not always true
}

.mat-mdc-standard-chip {
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-elevated-container-color: rgb(159, 200, 245);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgb(64, 116, 205);
}

html {
  scrollbar-color: rgba(128, 128, 128, 0.5) transparent;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  box-sizing: border-box;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  background-image: url(https://www.leishgem.org/imageResources/leishgembackground.svg);
  background-size: 50%;
  background-position: center var(--layout-searchbar-height);
  background-repeat: repeat-x;
  background-color: rgba(255, 255, 255, 1.0);
}

.tooltip {
  position: relative;
  cursor: help;
  text-decoration: underline dashed;
  user-select: none;
  -webkit-user-select: none;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.30);
  text-align: center;
  border-radius: var(--mdc-elevated-card-container-shape);
  padding: var(--layout-card-padding);
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 30%;
  margin-left: -30%;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-weight: normal;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 30%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext, .tooltip:active .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.card-container { // Container for all cards in the detailview
  width: calc(100% - var(--layout-card-padding) * 2); // Width minus the padding
  height: calc(100% - var(--layout-card-padding) * 2); // Height minus the padding
  padding: calc(var(--layout-card-padding) * 2) var(--layout-card-padding);
  display: flex; // Flex layout for the cards
  flex-wrap: wrap; // Allow wrapping of the cards - let them flow
  gap: calc(var(--layout-card-padding) * 2); // Space between cards
  align-content: flex-start; // Push cards to top instead of stretching them over available space
  overflow-y: auto; // Allow a scrollbar if height is exceeded
  scrollbar-color: rgba(128, 128, 128, 0.5) transparent;

  mat-card {
    flex: 1 0 min(100vw, calc(var(--layout-genelist-width) + 2 * var(--layout-card-padding)));
  }
}

mat-card.mat-mdc-card {
  padding-bottom: var(--layout-card-padding); // Leave some space at the bottom
  background-color: rgb(250, 250, 250, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.30);
  overflow: clip;

  mat-card-header.mat-mdc-card-header {
      padding: var(--layout-card-padding) calc(var(--layout-card-padding) * 1.5);
      justify-content: space-between; // Title on the left, icon on the right
      align-items: center; // Vertically center text and icon
      color: #4074cd;
      border-top-left-radius: var(--mdc-elevated-card-container-shape); // Rounded corners at the top
      border-top-right-radius: var(--mdc-elevated-card-container-shape);
  }

  mat-card-subtitle p {
    margin: 0;
  }
      
  p {
    padding: calc(var(--layout-card-padding) * 0.5) calc(var(--layout-card-padding) * 1.5);
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  ul, li {
    margin: 0;
    padding-right: calc(var(--layout-card-padding) * 1.5);
  }

  p:last-child { // The last paragraph doesn't need space at the bottom, that's taken care of by the card
      margin-bottom: 0px;
  }

  p.center {
      text-align: center;
  }

  hr { // Have one slightly darker blue line over the full width
      border: none;
      border-bottom: 1px solid rgba(238, 238, 238, 1.0);
      width: 100%;
  }

  h3 { // Small label for structured text items
      margin: 0;
      margin-top: 6px;
      margin-bottom: -6px;
      padding: 0 12px;
      font-size: 80%;
      font-weight: normal;
  }

  a:link, a:hover, a:visited, a:active {
    color: #4074cd;
    text-decoration: none;
  }
    
  a:hover {
    text-decoration: underline;
  }
}

mat-card.mat-mdc-invisiblecard {
  background: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.5);
}
